<template>
  <div class="checkbox">
    <input
      :id="$.uid"
      v-model="checkedRef"
      type="checkbox"
      aria-describedby="helper-msg-id"
      :true-value="'1'"
      :false-value="'0'"
      :disabled="!enabled"
    >
    <span
      class="checkbox__symbol"
    />
    <span
      v-if="label || useLabel"
      class="checkbox__label"
    >
      <label
        :for="$.uid"
        class=""
      >
        {{ label || (checkedRef === '1' ? 'On' : 'Off') }}
      </label>
    </span>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    // Refers to whether html element is enabled
    enabled: {
      type: Boolean,
      default: false,
    },

    // Controls whether checkbox is toggled on
    modelValue: {
      type: String,
      default: '0',
    },
    useLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const checkedRef = ref(null);
    // const checkedRef = computed(() => getValidPreferenceValues(props.modelValue));

    watch(() => props.modelValue, () => {
      checkedRef.value = props.modelValue;
    }, { immediate: true });

    watch(() => checkedRef.value, () => {
      emit('update:modelValue', checkedRef.value);
    });

    return {
      checkedRef,
    };
  },
};
</script>
