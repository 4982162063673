<template>
  <div class="preference-type">
    <div>
      <div class="preference-type-single__items">
        <div v-if="!validValues.length">
          No value found.
        </div>
        <div
          v-for="(item, index) in validValues"
          :key="index"
          data-arrow-navigable-item
          class="preference-type-single__item"
        >
          <div
            v-if="validBaseType === 'Integer' ||
              validBaseType === 'Decimal' ||
              validBaseType === 'Double'"
          >
            <input
              :id="'valueInput' + index"
              :value="displayAsNumber(item.value)"
              placeholder="Type value..."
              class="preference-type-single__input_item"
              type="number"
              disabled
            >
          </div>
          <div
            v-else-if="validBaseType === 'Boolean'"
            class="preference-type-single__item_boolean"
          >
            <!-- <span
              class="preference-type-single__item_boolean_name"
            >
              {{ preferenceValue.preferenceName }}
            </span> -->
            <checkbox
              v-model="item.value"
              :enabled="false"
            />
          </div>
          <div
            v-else-if="validBaseType === 'String'"
            class="preference-type-single__item_string"
          >
            <template
              v-if="customEditor === 'RtfEditor'"
            >
              <RtfViewer
                :name="preferenceName"
                :rtf="item.value"
              />
            </template>
            <template
              v-else
            >
              <textarea
                id="preference-type-single__text-area"
                v-model="item.value"
                class="preference-type-single__text-area"
                disabled
              />
            </template>
          </div>
          <div
            v-else-if="validBaseType === 'Date'"
          >
            <Datepicker
              ref="datepickerRef"
              v-model="item.value"
              :disabled="true"
              :placeholder="String(item.value) === 'null' ? 'Select date' : String(item.value) || 'Select date'"
              :format="datepickerFormat"
              :start-time="{
                hours: datepickerData.minimum.getHours(),
                minutes: datepickerData.minimum.getMinutes()
              }"
              :minutes-increment="5"
              :custom-props="datepickerData"
            />
          </div>
          <div
            v-else
          >
            <textarea
              id="preference-type-single__text-area"
              v-model="item.value"
              class="preference-type-single__text-area"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, reactive,
} from 'vue';

import Checkbox from '@/components/shared/checkbox.vue';
import RtfViewer from '@/components/shared/rtf-viewer.vue';

import { useUtilsStore } from '@/store/utils';

import Datepicker from '@vuepic/vue-datepicker';

export default {

  components: {
    Checkbox,
    RtfViewer,
    Datepicker,
  },

  props: {
    preferenceValueDTO: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { toHash } = useUtilsStore();

    const validValues = computed(() => props.preferenceValueDTO.values);
    const validBaseType = computed(() => props.preferenceValueDTO.baseType);
    const customEditor = computed(() => props.preferenceValueDTO.customEditor);
    const preferenceName = computed(() => props.preferenceValueDTO.name);

    const displayAsNumber = (value) => Number(value);
    const displayAsBoolean = (value) => value === '1';

    const addZeroIfNeeded = (number) => (number < 10 ? `0${number}` : `${number}`);
    // eslint-disable-next-line max-len
    const datepickerFormat = (date) => `${date.getFullYear()}-${addZeroIfNeeded(date.getMonth() + 1)}-${addZeroIfNeeded(date.getDate())}, ${addZeroIfNeeded(date.getHours())}:${addZeroIfNeeded(date.getMinutes())}`;
    const dateAsUTC = (date) => date && new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    const datepickerData = reactive({
      minimum: dateAsUTC(new Date()),
      value: null,
      maximum: dateAsUTC(new Date('2030-12-31T23:59')),
    });

    onMounted(() => {
      const inputElement = document.getElementById('preference-type-single__text-area');
      if (inputElement) {
        inputElement.style.height = `${inputElement?.scrollHeight}px`;
      }
    });

    return {
      displayAsNumber,
      displayAsBoolean,
      validValues,
      validBaseType,
      customEditor,
      preferenceName,
      toHash,
      datepickerData,
      datepickerFormat,
    };
  },
};
</script>

<style scoped>
.preference-type-single__items {
  display: block;
  max-width: 52rem;
}

.preference-type-single__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1.5rem 0.5rem 0;
}

.preference-type-single__item_boolean {
  display: flex;
}

.preference-type-single__item_boolean_name {
  padding-right: 1rem;
}

.preference-type-single__item_string {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.preference-type-single__text-area {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  overflow: hidden;
  color: var(--colour-text-default);
  resize: none;
  background: var(--color-bg-default);
}
</style>
